import { Visibility } from "authory-api-types/dist/enums";
import { ArticlePreviewResponse } from "authory-api-types/dist/types";
import { isDcmaGraylisted } from "authory-api-types/dist/utils/dcma";

export const getItemUrl = (item: ArticlePreviewResponse, itemsHref: string, collectionSlug?: string, isPrivateView?: boolean, isMainDomain: boolean = true) => {
    if (isPrivateView) return `${itemsHref}/${item.slug}`;

    const isDMCAProtected = isDcmaGraylisted(item.originalUrl);

    // for DMCA protected articles, always return original URL as they can't be published on authory
    if (isDMCAProtected && item.originalUrl) return item.originalUrl;

    if (collectionSlug) {
        return isMainDomain ?
            `${itemsHref}/${item.slug}?c=${collectionSlug}` :
            `/${item.slug}?c=${collectionSlug}`;
    }

    if (item.effectiveVisibility === Visibility.Preview && item.originalUrl) return item.originalUrl;

    return isMainDomain ? `${itemsHref}/${item.slug}` : `/${item.slug}`;
}


export const getItemTarget = (item: ArticlePreviewResponse, isPrivateView?: boolean) => {
    const isDMCAProtected = isDcmaGraylisted(item.originalUrl);

    // for DMCA protected articles, always open a new tab
    if (isDMCAProtected && item.originalUrl) return "_blank";

    return item.effectiveVisibility === Visibility.Preview && !isPrivateView ? "_blank" : undefined;
}