import { isBefore } from "date-fns";

export const getIsLegacyBio = (userSignupActivatedDate: string | null | undefined) => {
    try {
        const cuttoffDate = process.env.NEXT_PUBLIC_LEGACY_BIO_CUTTOF_DATE;

        if (!userSignupActivatedDate || !cuttoffDate) return false;

        return isBefore(new Date(userSignupActivatedDate), new Date(cuttoffDate));
    } catch {
        return false;
    }
}