import { NextPageContext } from "next";
import { QueryClient } from '@tanstack/react-query';
import { getUser } from "../api/user";
import { getAuthorQuerie } from "../types/queries";
import { UserResponse } from "authory-api-types/dist/types";
import { UserStatus } from "authory-api-types/dist/enums";
import { APPLICATION_ROUTES } from "../types/routes";
import { parseCookies } from "nookies";
import { useStagingPublicPageRedirect } from "./staging-public-page-check";

/**
 * This method is responsible for the logic to get author data on SSR at node level, ensures query reusability
 * @param user 
 * @param queryClient 
 * @param ctx 
 * @param cookies 
 * @param authorOnly 
 */
export const getAuthorServer = async (
    queryClient: QueryClient,
    ctx: NextPageContext,
    unavailableRoute = APPLICATION_ROUTES.UNAVAILABLE_PROFILE,
) => {
    const authorSlug = ctx.query.author as string;
    const cookies = parseCookies(ctx);
    let author: UserResponse | undefined;

    author! = await queryClient.fetchQuery({
        queryKey: getAuthorQuerie(authorSlug),
        queryFn: () => getUser(authorSlug)
    });

    const isInactive = author.status === UserStatus.Inactive || !author.active;

    // Inactive accounts, uncomfirmed free accounts or general staging access should all forward to unavailable profile
    if (isInactive || useStagingPublicPageRedirect(cookies)) {
        ctx.res!.writeHead(308, { Location: `${process.env.NEXT_PUBLIC_FRONTEND_URL}${unavailableRoute}` });
        ctx.res!.end();
        return;
    }

    return author;
}