import { UserSubscribe } from "authory-api-types/dist/endpoints";
import { API_URL } from "./index";
import axios from "axios";

export const subscribe = async ({ email, userId }: { email: string, userId: string }) => {
    const { data } = await axios.post<{ activationRequired: boolean }>(
        `${API_URL}${UserSubscribe.replace(":userId", userId)}`,
        {
            email
        }
    );

    return data;
};