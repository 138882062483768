import axios from "axios";
import { API_URL } from ".";
import { CertificateFilter, CertificateListResponse, CertificateResponse, CertificateUpdatePayload } from "authory-api-types/dist/types";
import { PublicUserCertificate, PublicUserCertificates, UpdateUserCertificate, UserCertificates } from "authory-api-types/dist/endpoints";
import { getHeaders } from "./get-headers";
import { CommonArgs } from "./collections";

export const getCertificateHistory = async (token: string | null, userSlug: string | undefined, params: CertificateFilter) => {
    const { data } = await axios.get<CertificateListResponse>(
        `${API_URL}${UserCertificates.replace(":userId", userSlug || "")}`,
        {
            params,
            ...getHeaders(token || "")
        }
    );

    return data;
};

interface UpdateCertificateArgs extends CommonArgs {
    params: CertificateUpdatePayload,
    certificateId: string
}

export const updateCertificate = async ({ token, userSlug, certificateId, params }: UpdateCertificateArgs) => {
    const { data } = await axios.post(`${API_URL}${UpdateUserCertificate.replace(":userId", userSlug).replace(":certificateId", certificateId)}`, params, getHeaders(token));
    return data;
}


export const getCertificate = async (userSlug: string | undefined, uuid: string | undefined) => {
    const { data } = await axios.get<CertificateResponse>(
        `${API_URL}${PublicUserCertificate.replace(":userId", userSlug || "").replace(":certificateId", uuid || "")}`
    );

    return data;
}

export const getPublicCertificates = async (userSlug: string | undefined, token: string | null) => {

    const config = !!token ? { ...getHeaders(token) } : {}

    const { data } = await axios.get<CertificateListResponse>(
        `${API_URL}${PublicUserCertificates.replace(":userId", userSlug || "")}`,
        config
    );

    return data;
};


